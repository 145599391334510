import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LogoComponent } from '@/shared/components/fragments/logo/logo.component';
import { NavActions } from '@/shared/store/actions/nav.actions';
import { selectIsOpened } from '@/shared/store/selectors/nav.selectors';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  imports: [MatToolbarModule, AsyncPipe, MatIconModule, LogoComponent, MatButtonModule],
})
export class ToolbarComponent implements OnInit {
  open$: Observable<boolean> | undefined;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.open$ = this.store.select(selectIsOpened);
  }

  closeSideNav(): void {
    this.store.dispatch(NavActions.toggleClose());
  }

  openSideNav(): void {
    this.store.dispatch(NavActions.toggleOpen());
  }

  toggle(): void {
    this.open$?.pipe(take(1)).subscribe((isOpen: boolean) => {
      if (isOpen) {
        this.closeSideNav();
      } else {
        this.openSideNav();
      }
    });
  }
}
